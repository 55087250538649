html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
    background: #F9FAFB;
}

/*h1,h2,h3,h4,h5,h6,p{*/
/*  margin: 0px;*/
/*}*/

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

button {
    color: inherit;
}

* {
    box-sizing: border-box !important;
    font-family: 'Manrope', sans-serif !important;
    line-height: normal;
}

.background {
    position: relative;
}

button {
    cursor: pointer;
}

.overflow-y {
    overflow-y: scroll;
}

.overflow-y::-webkit-scrollbar {
    display: none;
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

.custom-scroll-bar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    cursor: pointer;
}

/* Handle on hover */
.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

a * {
    cursor: pointer;
}

.introjs-tooltip-header {
    padding-top: 20px;
}

.introjs-tooltip-title {
    font-size: 16px;
}

.introjs-tooltiptext {
    font-size: 12px;
}

.introjs-dontShowAgain label {
    font-size: 12px !important;
}

.introjs-button {
    font-size: 12px;
    border-radius: 5px;
    text-shadow: none;
    font-weight: bold;
    border: none !important;
    box-shadow: none !important;
}

.introjs-prevbutton {
    background: rgb(235, 235, 235) !important;
    color: rgb(29, 29, 29) !important;
}

.introjs-nextbutton {
    background: #006F3E !important;
    color: #fff !important;
}
